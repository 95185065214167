.ServicesWrapper {
    margin-top: 60px;
    background: linear-gradient(0deg, rgba(0, 5, 27, 0.25) 0%, rgba(0, 5, 27, 0.00) 68.81%), #5565FF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    overflow: hidden;

}

.headerText {
    color: #FFF;
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    margin-top: 100px;
    margin-left: 300px;
}

.upBoxWrapper {
    width: 54.4%;
}

.AIwrapper {
    border-radius: 45px 0px 0px 45px;
    overflow: hidden;
    background-color: #2b3155;
    background-image: url("../../../Images/services/AI.webp");
    width: 45%;
    height: 910px;
    margin-left: 10px;
    cursor: pointer;
}

.ComputerVisionWrapper {
    background-color: #5c657d;
    background-image: url("../../../Images/services/ComputerVision.webp");
    width: 100%;
    height: 380px;
    border-radius: 0px 45px 45px 0px;
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.VoiceWrapper {
    background-color: #cfcee0;
    background-image: url("../../../Images/services/voice.webp");
    width: 671px;
    height: 520px;
    border-radius: 0px 45px 45px 0px;
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.EducationWrapper {
    background-color: #85868b;
    margin-left: 10px;
    background-image: url("../../../Images/services/education.webp");
    width: 364px;
    height: 520px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}



.miniBoxWrapper {
    display: flex;
    margin-top: 10px;
}

.BlocksWrapperForMobile {
    display: none;
}


.blocksWrapper {
    display: flex;
    margin-top: 60px;
}

.ComputerVisionText {
    color: var(--c, #FAFAFF);
    text-align: right;
    font-family: "Manrope";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width: 760px;
    text-align: end;
    margin: 50px;
}

.AIText {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    max-width: 610px;
    margin: 50px;
}

.VoiceText {
    color: var(--2, #0F0F28);
    text-align: right;
    font-family: "Manrope";
    font-size: 57px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    max-width: 450px;
    margin: 40px;
}

.EducationText {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 40px;
    margin-left: 20px;

}

@media(max-width:1700px) {
    .VoiceText {
        font-size: 45px;
        margin-right: 10px;
    }

}

@media(max-width:1400px) {
    .headerText {
        margin-left: 40px;
    }

    .VoiceText {
        font-size: 55px;
        margin-right: 20px;
    }

    .blocksWrapper {
        flex-direction: column;
        align-items: center;
    }

    .upBoxWrapper {
        width: 100%;
        max-width: 865px;

    }

    .AIwrapper {
        background-image: url("../../../Images/services/AI.webp");
        width: 100%;
        max-width: 865px;
        height: 910px;
        margin-left: 0;
        border-radius: 65px;
        margin-top: 10px;
    }

    .ComputerVisionWrapper {
        background-image: url("../../../Images/services/ComputerVision.webp");
        width: 100%;
        height: 380px;
        border-radius: 45px;
    }

    .VoiceWrapper {
        background-image: url("../../../Images/services/voice.webp");
        width: 671px;
        height: 520px;
        border-radius: 45px;
    }

    .EducationWrapper {
        margin-left: 10px;
        background-image: url("../../../Images/services/education.webp");
        width: 364px;
        height: 520px;
        border-radius: 45px;
    }
}

@media(max-width:900px) {
    .blocksWrapper {
        display: none;
    }

    .headerText {
        margin-left: 10px;
        font-size: 40px;
    }

    .BlocksWrapperForMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

    }
}