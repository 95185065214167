.projectJustifier{
    display: flex;
    justify-content: center;
}
.projectDescWrapper {
    height: 810px;
    background-color: #7487c2;
    background-image: url("../../../Images/products_bg.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow:hidden;
    z-index: 0;
    width: 100%;
    max-width: 1920px;
}
.topTextBlock{
    width: 100%;
    margin-left: 300px;
    margin-top: 190px;
}
.topTextTitle{
    color: var(--c, #FAFAFF);
    font-family: Manrope;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.topTextSubTitle{
    margin-top: 50px;
    color: var(--c, #FAFAFF);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
}

@media(max-width:1200px){
    .topTextBlock{
        width: auto;
        margin-left: 0px;
        margin-top: 10vw;
    }
    .projectDescWrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 68vw;
    }
}

@media(max-width:920px){
    .topTextBlock{
        margin-top: 100px;
    }
    .projectDescWrapper{
        height: 650px;
    }
}

@media(max-width: 768px) {
    .topTextTitle{
        font-size: 48px;
        text-align: center;
    }
    .topTextSubTitle{
        font-size: 14px;
    }
}

@media(max-width: 560px) {
    .topTextSubTitle{
        font-size: 14px;
        width: 90%;
        margin-left:5%;
    }
}

@media(max-width: 480px) {
    .topTextTitle{
        font-size: 40px;
        text-align: center;
    }
}