.ProjectsWrapper {
    background: #FFF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.HeaderText {
    color: #00051B;

    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.meowWrapper {
    background-color: #455998;
    cursor: pointer;
    width: 515px;
    height: 810px;
    border-radius: 45px;
    background-image: url("../../../Images/meow.webp");
    /* проект-блюр */
    backdrop-filter: blur(4px);

}

.mobitestWrapper {
    background-color: #455998;
    cursor: pointer;
    width: 745px;
    height: 350px;
    flex-shrink: 0;
    border-radius: 45px;
    background-image: url("../../../Images/mobitest.webp");
}

.sdrWrapper {
    background-color: #455998;
    cursor: pointer;
    border-radius: 45px;
    width: 745px;
    height: 310px;
    background-image: url("../../../Images/srd.webp");
}


.buttonWrapper {
    cursor: pointer;
    width: 100%;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    background: #4A6FF5;

    color: #FFF;

    text-align: center;
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    transition: 0.2s;
}

.buttonWrapper:hover {
    background: #4a6ff5bb;
}

.rideSideProject {
    width: 745px;
    height: 810px;
}

.blockWrapper {
    display: flex;
    margin-top: 60px;

}

.ArrowWrapper {
    display: flex;
    justify-content: end;
    margin-right: 40px;
    margin-top: 40px;
    user-select: none;
}

.ArrowWrapper2 {
    display: flex;
    justify-content: end;
    padding-right: 40px;
    padding-top: 40px;
    user-select: none;
}

.HeaderProjectText {
    color: var(--c, #FAFAFF);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.SmallProjectText {
    color: var(--c, #FAFAFF);
    /* НЦ текст */
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

@media(max-width:1300px) {
    .blockWrapper {
        flex-direction: column;
        align-items: center;
    }

    .meowWrapper {
        margin-left: 60px;
        margin-bottom: 40px;
    }

    .mobitestWrapper {
        width: 515px;
    }

    .sdrWrapper {
        width: 515px;
    }

    .HeaderProjectText {
        max-width: 370px;
    }

    .rideSideProject {
        width: 515px;
    }

    .HeaderText {
        margin-left: 40px;
    }

}

@media(max-width:600px) {
    .blockWrapper {
        flex-direction: column;
        align-items: center;
    }

    .meowWrapper {
        margin-left: 0px;
        margin-bottom: 40px;
        width: 350px;
        height: 700px;
    }

    .mobitestWrapper {
        margin-left: -30px;
        width: 350px;
    }

    .sdrWrapper {
        margin-left: -30px;
        width: 350px;
    }

    .HeaderProjectText {
        max-width: 320px;
    }

    .SmallProjectText {
        max-width: 300px;
    }

    .rideSideProject {
        width: 350px;
    }

    .buttonWrapper {
        margin-left: -30px;
    }


}

@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

}