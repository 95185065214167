.blackbg {
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallSize {
    display: none;
}


@media(max-width:1320px) {
    .smallSize {
        display: flex;
    }

    .bigSize {
        display: none;
    }


}