.achievementHolder {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 50px;
}


.achievementBlock {
    width: 255px;
    height: 123.13px;
    background: #4A6FF5;
    box-shadow: 2px 2px 8px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    margin-right: 25.55px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.achievementBlockText {
    color: var(--c, #FAFAFF);
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    white-space: pre-wrap;
}

.achievementBlockHeader {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    padding-top: 15px;
}