.linkArrowBox{
    height: 100px;
    width: inherit;
    height: inherit;
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
}
.linkArrowJustifier{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.linkArrow{
    margin-top: 40px;
    margin-right: 40px;
    width: 45px;
    height: 45px;
    background-image: url("../../../Images/link.webp");
    background-repeat: no-repeat;
    background-size: contain;
}
.projectJustifier{
    display: flex;
    justify-content: center;
}
.projectContainerWrapper{
    border-radius: 45px 45px 0px 0px;
    background: white;
    height: fit-content;
    margin-top: -310px;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: 1920px;
    justify-content: center;
    margin-bottom: 100px;
}
.projectHolder{
    width: 1320px;
    margin-top: 160px;
}

.threeProjectBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 810px;
}

.leftBox{
    cursor:pointer;
    background-image: url("../../../Images/meow_project.webp");
    background-size: cover;
    background-color: #2d3c71;
    border-radius: 45px;
    width: 515px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.leftBoxText{
    margin-left: 5%;
    margin-bottom: 6%;
}
.boxTitle{
    color: var(--c, #FAFAFF);
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    width: 90%;
}

.boxSubTitle{
    color: var(--c, #FAFAFF);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 22px;
    width: 77%;
}

.rightBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    width: 745px;
}

.topRightBox{
    cursor:pointer;
    background-image: url("../../../Images/mobitest_project.webp");
    background-size: cover;
    background-color: #031135;
    border-radius: 45px;
    width: inherit;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.topRightBoxText{
    margin-left: 5%;
    margin-bottom: 6%;
}

.bottomRightBox{
    cursor:pointer;
    background-image: url("../../../Images/srd_project.webp");
    background-size: cover;
    background-color: #031135;
    border-radius: 45px;
    width: inherit;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.bottomRightBoxText{
    margin-left: 5%;
    margin-top: 6%;
}

@media(max-width:1500px){
    .projectHolder{
        width: 88%;
        margin-top: 10%;
    }
    .threeProjectBox{
        height: 54vw;
    }
    .leftBox{     
        width: 34.34vw
    }
    .rightBox{
        width: 49.667vw;
    }
    .topRightBox{
        height: 25vw;
    }
    .topRightBoxText{
        width: 88%;
    }
    
    
    .bottomRightBox{
        height: 25vw;
    }
    .bottomRightBoxText{
        width: 88%;
    }
    .linkArrow{
        margin-top: 30px;
        margin-right: 30px;
        width: 30px;
        height: 30px;
    }
}

@media(max-width:1000px){
    .boxTitle{
        font-size: 16.5px;
    }
    .boxSubTitle{
        font-size: 15.5px;
    }
}


@media(max-width:768px){
    .projectContainerWrapper{
        border-radius: 20px 20px 0px 0px;
    }
    .leftBox{
        border-radius: 20px;
        height: 50vw;
        width: 90vw;
        background-position: center;
    }
    .leftBoxText{
        width: 88%;
    }
    .threeProjectBox{
        height: 158vw;
        flex-direction: column;
    }
    .rightBox{
        height: 104vw;
    }
    .topRightBox{
        border-radius: 20px;
        background-position: center;
        height: 50vw;
        width: 90vw;
    }
    .bottomRightBox{
        border-radius: 20px;
        background-position: center;
        height: 50vw;
        width: 90vw;
    }
    .boxTitle{
        font-size: 20px;
    }
    .boxSubTitle{
        font-size: 18px;
    }
    .bottomRightBox{
        justify-content: end;
    }
    .bottomRightBoxText{
        margin-bottom: 6%;
    }
    .linkArrow{
        margin-top: 30px;
        margin-right: 30px;
        width: 40px;
        height: 40px;
    }
}

@media(max-width:480px){
    .boxTitle{
        font-size: 16px;
    }
    .boxSubTitle{
        font-size: 14px;
    }
    .linkArrow{
        margin-top: 15px;
        margin-right: 10px;
        width: 25px;
        height: 25px;
    }
}

@media(max-width:380px){
    .boxTitle{
        font-size: 15px;
    }
    .boxSubTitle{
        font-size: 13px;
    }
}