
.commercialOfferWrapper {
    opacity: 1;
    max-width: 360px;
    height: 630px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 2px 5px 10px 0px rgba(89, 116, 130, 0.25);
    display: flex;
}

.leftSide {
    flex: 1;
    /* background: rgb(41, 0, 128, 0.2); */
}

.rightSide {
    flex: 1;

}

.headerText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.header2Text {
    margin-top: 22px;
    max-width: 383px;
    color: #201644;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.smallText {
    margin-top: 3px;
    color: #383842;
    font-family: "Raleway";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.inputName {
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #4A6FF5;
    background: var(--c, #FAFAFF);
    padding: 10px 0px 10px 20px;
    outline: 0;
    outline-offset: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    width: 310px;
}

.inputMail::placeholder {
    color: #383842;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.inputMail {
    width: 239px;
    padding: 10px 0px 10px 20px;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #4A6FF5;
    background: var(--c, #FAFAFF);
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    outline: 0;
}

.inputName::placeholder {
    color: #383842;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.selectName {
    margin-top: 12px;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid #4A6FF5;
    background: var(--c, #FAFAFF);
    padding: 10px 0px 10px 20px;
    outline: 0;
    outline-offset: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    width: 330px;
    height: 39px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #383842;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    
}

.selectName::placeholder{
    color: #383842;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.selectNameElement {
    color: #383842;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.buttonSend {
    margin-top: 20px;
    border-radius: 15px;
    background: #4A6FF5;
    display: flex;
    width: 330px;
    height: 39px;
    justify-content: center;
    align-items: center;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    cursor: pointer;
}