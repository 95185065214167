.CompanyDescriptionWrapper {
    height: 460px;
    background-color: #131d27;
    background-image: url("../../../Images/aboutBackground.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 1920px;
}

.HeaderText {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.AdvantagesWrapper {
    display: flex;
}


@media(max-width:1200px) {
    .CompanyDescriptionWrapper {
        height: 460px;
        background-size: cover;
    }
}

@media(max-width:750px) {
    .HeaderText {
        font-size: 26px;
    }
}