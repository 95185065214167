:root {
    --eMailBoxWrapper-width: 0px;
    --eMailBoxWrapper-right: -10px;
}

.eMailBoxWrapper {
    position: absolute;
    margin-top: 20px;
    bottom: 190px;
    right: var(--eMailBoxWrapper-right);
    overflow: hidden;
    width: var(--eMailBoxWrapper-width);
    transition: 0.3s;
}

.contactJustifier {
    display: flex;
    justify-content: center;
}

.messageQuestions {
    margin-top: 40px;
    border-radius: 20px;
    border: 2px solid var(---, #576982);
    outline: none;
    top: 54px;
    display: inline-flex;
    padding: 30px 0 55px 30px;
    align-items: center;
    gap: 10px;
    width: 89%;
    height: 40px;
    resize: none;

    color: black;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.messageQuestions::placeholder {
    color: var(---, #576982);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.contactSupportText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.contactContainerWrapper {
    border-radius: 45px 45px 0px 0px;
    background: white;
    height: fit-content;
    margin-top: -355px;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: 1920px;
    justify-content: center;
    margin-bottom: 100px;
}

.contactHolder {
    background-color: white;
    width: 1320px;
    margin-top: 105px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contactText {
    color: #201644;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 10px;
}

.contactBold {
    color: #201644;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 10px;
}

.contactTextBox {
    margin-top: 50px;
}

.contactSideText {
    text-align: right;
    color: #201644;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 40px;
}

.contactSidePanel {
    margin-bottom: 70px;
}

.emailInputStyle {
    border-radius: 15px;
    border: 2px solid var(--2, #0F0F28);
    width: 117px;
    padding: 15px 160px 15px 30px;
    align-items: center;
    color: var(---, black);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.emailInputStyle::placeholder {
    color: var(---, #576982);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.buttonSendMail {
    cursor: pointer;
    border-radius: 15px;
    background: #4A6FF5;
    width: 230px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;


    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    transition: 0.2s;
}

.buttonSendMail:hover{
    background: #4a6ff5bb;
}

@media(max-width:1500px) {
    .contactHolder {
        width: 88%;
    }
}

@media(max-width:1300px) {
    .eMailBoxWrapper{
        position: initial;
    }
    .contactHolder {
        flex-direction: column;
    }

    .contactText {
        font-size: 20px;
        text-align: center;
    }

    .contactBold {
        font-size: 20px;
        text-align: center;
    }

    .noBr {
        display: none;
    }

    .contactBoxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media(max-width:1000px) {
    .contactContainerWrapper {
        margin-top: -35vw;
    }
}

@media(max-width:768px) {
    .contactContainerWrapper {
        margin-top: -28vw;
    }
}


@media(max-width:480px) {

    .contactSupportText{
        font-size: 12px;
    }
    .contactText {
        font-size: 16px;
        text-align: left;
    }

    .contactBold {
        font-size: 16px;
        text-align: left;
    }

    .contactContainerWrapper {
        margin-top: -16vw;
    }

    .noBr {
        display: inline;
    }

    .contactSideText {
        font-size: 16px;
    }
}