:root {
    --AdaptiveMenuWrapper-margin-top: -405px;
    --ServiseElement-color: #FAFAFF;
    --ProjectsElement-color: #FAFAFF;
    --POElement-color: #FAFAFF;
    --ProductsElement-color: #FAFAFF;
    --AboutElement-color: #FAFAFF;
    --ContactsElement-color: #FAFAFF;
    --AdaptiveMenuWrapper-background: rgba(15, 15, 40, 0.70);
    --line-background: #FAFAFF;
}


.ServiseElement {
    color: var(--ServiseElement-color);
}

.ProjectsElement {
    color: var(--ProjectsElement-color);

}

.POElement {
    color: var(--POElement-color);
}

.ProductsElement {
    color: var(--ProductsElement-color);
}

.AboutElement {
    color: var(--AboutElement-color);
}

.ContactsElement {
    color: var(--ContactsElement-color);
}


.AdaptiveMenuWrapper {
    width: 100vw;
    height: 385px;
    border-radius: 0px 0px 15px 15px;
    background: var(--AdaptiveMenuWrapper-background);
    backdrop-filter: blur(20px);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s;
    margin-top: var(--AdaptiveMenuWrapper-margin-top);
}

.inWrapper {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 90px;

}

.HeaderElement {
    margin-left: 15px;
    /* color: var(--c, #FAFAFF); */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    cursor: pointer;
}

.HeaderElement:hover {
    color: var(--c, #b8b8b8);
}


.line {
    height: 1px;
    width: 155px;
    background: var(--line-background);
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}