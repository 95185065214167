.projectJustifier{
    display: flex;
    justify-content: center;
}
.projectContainerWrapper{
    border-radius: 45px 45px 0px 0px;
    background: white;
    height: fit-content;
    margin-top: -310px;
    z-index: 2;
    display: flex;
    width: 100%;
    max-width: 1920px;
    justify-content: center;
    margin-bottom: 100px;
}
.projectHolder{
    width: 1320px;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.projectHolder::after{
    content: "";
    flex: auto;
}

@media(max-width:1500px){
    .projectHolder{
        width: 88%;
        margin-top: 6.66vw;
    }
}

@media(max-width:768px){
    .projectContainerWrapper{
        border-radius: 20px 20px 0px 0px;
    }
   
}
@media(max-width:480px){
    .boxTitle{
        font-size: 16px;
    }
    .boxSubTitle{
        font-size: 14px;
    }
    .linkArrow{
        margin-top: 15px;
        margin-right: 10px;
        width: 25px;
        height: 25px;
    }
}