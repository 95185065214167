:root {
    --ServiseElement-background: none;
    --aboutElement-background: none;
    --ProjectsElement-background: none;
    --POElement-background: none;
    --ProductsElement-background: none;
    --ContactsElement-background: none;
    --HeaderElement-color: #FAFAFF;
    --HamburgerWrapper-color: #FAFAFF;

}

.ServiseElement {
    background: var(--ServiseElement-background);
}

.ProjectsElement {
    background: var(--ProjectsElement-background);
}

.POElement {
    background: var(--POElement-background);
}

.ProductsElement {
    background: var(--ProductsElement-background);
}

.aboutElement {
    background: var(--aboutElement-background);
}

.ContactsElement {
    background: var(--ContactsElement-background);
}

.HeaderWrapper {
    margin-top: 20px;
    padding-left: 15px;
    /* padding-right: 15px; */
    width: 1305px;
    display: flex;
}

.HeaderElement {
    color: var(--HeaderElement-color);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    padding: 10px 18px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 25px;
}

.HeaderElement:hover {
    color: var(--c, #b8b8b8);
}


.ActiveElement {
    background: #4A6FF5;
}

.HeaderElementWrapper {
    display: flex;
    align-self: center;
}

.logoStyle {
    margin-right: 120px;
    z-index: 1;
    cursor: pointer;
}

.HamburgerWrapper {
    display: flex;
    align-self: center;
    color: var(--HamburgerWrapper-color);
    display: none;
}

.AdaptiveMenu {
    display: none;

}

.GlobalWrapper {
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100vw;
    display: flex;
    justify-content: center;

}

.imgLogo {
    width: 120px;
}


@media(max-width:1200px) {
    /* .logoStyle {
        display: none;
    } */
}

@media(max-width:1300px) {
    .HeaderElement {
        font-size: 20px;
        padding: 5px 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media(max-width:1000px) {
    .HeaderElement {
        font-size: 16px;

    }
}

@media(max-width:900px) {
    .HeaderElementWrapper {
        display: none;

    }

    .logoStyle {
        display: block;
    }

    .imgLogo {
        width: 100px;
    }

    .HamburgerWrapper {
        display: block;
    }

    .burgerMargin {
        flex: 1;
    }

    .AdaptiveMenu {
        display: block;
    }

    /* .GlobalWrapper {
        display: block;
    } */
}