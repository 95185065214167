.socialsBox{
    width: 100%;
    height: 120px;
    border-radius: 35px;
    background: #576982;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    max-width: 1920px;
}
.justifier{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.socialsHolder{
    width: 1090px;
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.socialsAligner{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.socialsTitle{
    color:  #FAFAFF;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
}
.arrow{
    margin-left: 30px;
    margin-right: 45px;
    user-select: none;
}
.icon{
    margin-left: 135px;
    cursor: pointer;
    user-select: none;
}
@media(max-width:1300px){
    .socialsHolder{
        width: 84vw;
        display: flex;

        justify-content: space-between;
    }
    .arrow{
        margin:0;
        display: none
    }
    .icon{
        margin: 0;
    }
}

@media(max-width:768px){
    .socialsBox{
        height: 80px;
    }
    .socialsTitle{
        font-size: 24px;
    }
    .arrow{
        margin:0;
        display: none;
        
    }
    .icon{
        margin: 0;
        margin-top: 4px;
        width: 50px;
        height: 50px;
    }
}

@media(max-width:480px){
    .socialsTitle{
        display: none;
    }
}