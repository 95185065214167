.ProjectsWrapper {
    background: #FFF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.catWrapper {
    position: absolute;
    left: calc(50vw - 960px);
    width: 1920px;
    height: 395px;

    /* background: red; */
    background-image: url("../../../../../Images/RoboLogo/cat.webp");

    margin-top: -420px;
    z-index: 4;

}

.firstText {
    color: var(--2, #0F0F28);
    /* НЦ Заголовок */
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    max-width: 1319px;
}

.secondText {
    color: var(--text, #201644);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    max-width: 1100px;
}

.thirdText {
    color: var(--2, #0F0F28);
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.textWrapper {
    margin-left: -10px;
}

.buttonWrapper {
    margin-top: 30px;
    width: 400px;
    height: 59px;
    border-radius: 35px;
    background: #4a6ff5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
}

.buttonWrapper:hover {
    background: #4a6ff5bb;
}

.buttonText {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.listWrapper {
    max-width: 975px;
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-left: -20px;
}

.listWithIconsWrapper {
    display: flex;
    align-items: end;
}

.iconsWrapper {
    position: relative;
}

.iconsSubWrapper {
    position: absolute;
    top: -220px;
}

.edstyle{
    position: absolute;
    bottom: -200px;
}

.ed1style{
    position: absolute;
    bottom: 0;
}

.ed3style{
    position: absolute;
    bottom: 70px;
    right: -170px;
}

.ed2style{
    position: absolute;
    bottom: 40px;
    right: -170px;
    z-index: -1;
} 

/* 
.ed4style{
    position: absolute;
    bottom: 0;
} */

.mailStyle{
    margin-top: 40px;
    max-width: 975px;
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

@media(max-width:1920px) {
    .catWrapper {
        width: 100vw;
        left: 0;
    }
}



@media(max-width:1400px) {
    .iconsWrapper {
        display: none;
    }


}

@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

    .FSIwrapper {
        width: 100%;
    }

    .textWrapper {
        width: 900px;
    }

}

@media(max-width:700px) {
    .firstText {
        font-size: 20px;
    }

    .HeaderText {
        font-size: 20px;
    }

    .secondText {
        font-size: 12px;
    }

    .MediumText {
        font-size: 12px;
    }

    .SmallText {
        font-size: 10px;
    }

    .thirdText {
        font-size: 32px;
    }

    .buttonWrapper {
        margin-top: 30px;
        width: 300px;
        height: 40px;
    }

    .buttonText {
        font-size: 20px;
    }
}












.CompanyDescriptionWrapper {
    height: 854px;
    background-image: url("../../../../../Images/RoboLogo/ability.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 100px;
}

.HeaderText {
    max-width: 975px;
    color: var(--c, #FAFAFF);
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.SmallText {
    max-width: 755px;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.HeaderText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.MediumText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.SmallText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.svgWrapper {
    position: absolute;
    left: -80px;
    top: 0;
}

.BlockTextWrapper {
    width: 1305px;
    margin-top: 60px;
    padding: 10px;
    position: relative;
}

.FSIwrapper {
    display: flex;
    align-items: center;
}

.listHeader {
    margin-top: 70px;
    margin-bottom: 25px;
    font-weight: 500;
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 29px;
}


@media(max-width:1550px) {
    .CompanyDescriptionWrapper {
        height: 600px;
        background-size: cover;
    }
}

@media(max-width:1200px) {
    .CompanyDescriptionWrapper {
        height: 500px;
        background-size: cover;
    }

    .BlockTextWrapper {
        display: none;
    }
}

@media(max-width:1000px) {
    .CompanyDescriptionWrapper {
        height: 400px;
        background-size: cover;
    }
}

@media(max-width:800px) {
    .CompanyDescriptionWrapper {
        height: 350px;
        background-size: cover;
    }
}



@media(max-width:750px) {
    .CompanyDescriptionWrapper {
        background-size: contain;
        background-image: url("../../../../../Images/RoboLogo/Group_1.webp");
        width: 100%;
        height: 1000px;
    }
}

@media(max-width:1550px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

    .BlockTextWrapper {
        width: 1005px;
    }
}

@media(max-width:1200px) {

    .FSIwrapper {
        flex-direction: column;
    }

    .FSI {
        margin-top: 50px;
        margin-left: 0;
    }
}


@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }
}

@media(max-width:800px) {
    .SuccessStoryText {
        font-size: 48px;
    }
}

@media(max-width:600px) {

    .svgWrapper {
        display: none;
    }

}