.ClientsWrapper {
    background: #FFF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
}




.feedbackWrapper {
    display: flex;
    align-items: center;
}

.smallText {
    color: var(--2, #0F0F28);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    flex-direction: column;
    justify-content: center;
}

.emailInputStyle {
    border-radius: 15px;
    border: 2px solid var(--2, #0F0F28);
    width: 160px;
    padding: 15px 160px 15px 30px;
    align-items: center;
    color: var(---, black);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.emailInputStyle::placeholder {
    color: var(---, #576982);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.downloaderText {
    color: var(--2, #0F0F28);
    /* НЦ текст */
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.buttonSendMail {
    cursor: pointer;
    border-radius: 15px;
    background: #4A6FF5;
    width: 260px;
    padding: 10px 40px;
    align-items: center;

    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    transition: 0.2s;
}

.buttonSendMail:hover{
    background: #4a6ff5bb;
}

.spareElement {
    margin-left: 90px;
    margin-right: 90px;
}




@media(max-width:1100px) {
    .spareElement {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media(max-width:900px) {
    .spareElement {
        display: none;
    }

    .feedbackWrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .sendFormWrapper {
        margin-top: 50px;
    }
}

@media(max-width:450px) {

    .sendFormWrapper {
        margin-top: 100px;
    }



}