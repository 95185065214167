.ProjectsWrapper {
    background: #FFF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.HeaderText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.MediumText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.SmallText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.svgWrapper {
    position: absolute;
    left: -80px;
    top: 0;
}

.BlockTextWrapper {
    width: 1305px;
    margin-top: 60px;
    padding: 10px;
    position: relative;
}

.FSIwrapper {
    display: flex;
    align-items: center;
}

.FSI {
    margin-left: 100px;
}

.SuccessStoryText {
    color: #4A6FF5;
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

@media(max-width:1550px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

    .BlockTextWrapper {
        width: 1005px;
    }
}

@media(max-width:1200px) {
    .BlockTextWrapper {
        width: 800px;
    }

    .FSIwrapper {
        flex-direction: column;
    }

    .FSI {
        margin-top: 50px;
        margin-left: 0;
    }
}


@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

    .BlockTextWrapper {
        width: 600px;
    }
}

@media(max-width:800px) {
    .BlockTextWrapper {
        width: 400px;
    }

    .SuccessStoryText {
        font-size: 48px;
    }
}

@media(max-width:600px) {
    .BlockTextWrapper {
        width: 300px;
    }

    .svgWrapper {
        display: none;
    }

}