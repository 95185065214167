.text {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.underlinedText {
    color: var(---, #576982);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-decoration-line: underline;
    cursor: pointer;
}