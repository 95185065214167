.ProjectsWrapper {
    background: var(---, #576982);
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: 100px;
}

.elementWrapper {
    margin-left: 50px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 295px;
    height: 416px;
    object-fit: cover;
}

.BlockTextWrapper {
    width: 1305px;
    margin-top: 50px;
    padding: 10px;
    position: relative;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: center;
}

.imagesWrapper {

    display: flex;
    align-items: center;
    position: relative;
}

.imagesWrapperPartners {
    width: 1920px;
    overflow: auto;
}

@media(max-width:1920px) {
    .imagesWrapperPartners {
        width: 100vw;
        overflow: auto;
    }
}

@media(max-width:1550px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }
}

@media(max-width:1350px) {
    .BlockTextWrapper {
        font-size: 48px;
        text-align: center;
    }
}


@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }
}

@media(max-width:900px) {
    .BlockTextWrapper {
        font-size: 32px;
        text-align: center;
    }
}

@media(max-width:600px) {
    .BlockTextWrapper {
        font-size: 24px;
        text-align: center;
    }
}

@media(max-width:450px) {
    .BlockTextWrapper {
        font-size: 18px;
        text-align: center;
    }
}