

.contactTitleHolder {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    width: 750px;
    overflow: hidden;

}

.contactTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #201644;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    width: 97.5%;
}

.plus {
    cursor: pointer;
    transition: 0.3s;
    user-select: none;
}

.contactContent {
    transition: 0.3s;
    height: 300px;
}



@media(max-width:850px) {
    .contactTitleHolder {
        width: 88vw;
    }

    .contactTitle {
        font-size: 18px;
    }

    .plus {
        width: 25px;
        height: 25px;
    }
}

@media(max-width:480px) {
    .contactTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .onlyTitle {
        width: 75%;
    }

    .plus {
        width: 40px;
        height: 40px;
    }
}