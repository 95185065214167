.footerJustifier{
    
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footerHolder{
    max-width: 1920px;
    width: 1320px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.info{
    width: 57%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.infoHalf{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 48%;
}
.text{
    margin-top: 12px;
    color: #201644;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
}
@media(max-width:1500px){
    .footerHolder{
        width: 88%;
    }
}
@media(max-width:1200px){
    .info{
        width: 100%;
    }
    .footerHolder{
        flex-direction: column;
    }
    .copyright{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .infoHalf{
        width: 45%;
    }
}
@media(max-width:768px){
    .info{
        flex-direction: column;
        margin-bottom: 30px;
    }
    .infoHalf{
        width: 100%;
    }
}