.textPrice {
    color: var(--2, #0F0F28);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.ClientsWrapper {
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -100px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.HeaderText {
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.Header2Text {
    margin-top: 20px;
    color: var(--2, #0F0F28);
    /* НЦ Заголовок */
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.feedbackWrapper {
    display: flex;
}

.smallText {
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.emailInputStyle {
    border-radius: 15px;
    border: 2px solid var(--2, #0F0F28);
    width: 160px;
    padding: 15px 160px 15px 30px;
    align-items: center;
    color: var(---, black);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.emailInputStyle::placeholder {
    color: var(---, #576982);
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.buttonSendMail {
    cursor: pointer;
    border-radius: 15px;
    background: #4A6FF5;
    width: 350px;
    padding: 12px 0 12px 0;
    align-items: center;

    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: center;
}

.buttonSendMail:hover {
    background: #4a6ff5bb;
}

.buttonDownload {
    cursor: pointer;
    border-radius: 15px;
    background: white;
    border: 2px solid #4A6FF5;
    width: 350px;
    padding: 12px 0 12px 0;
    align-items: center;

    color: #4A6FF5;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: center;
    text-decoration: none;
}

.buttonDownload:hover {
    background: #FAFAFF;
    text-decoration: none;
}


.buttonDownloadList {
    cursor: pointer;
    border-radius: 15px;
    width: 354px;
    height: 150px;
    background: var(---, #576982);

}

a{
    text-decoration: none;
}

.spareElement {
    margin-left: 90px;
    margin-right: 90px;
}

.sendFormWrapper {
    margin-top: 15px;
}

.buttonDownloadListText {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.linkStyle {
    color: white;
    text-decoration: none;
}

.linkStyle:visited {
    color: white;
    text-decoration: none;
}

.linkStyle:focus {
    color: #0F0F28;
    text-decoration: underline;
}


.linkStyle:active {
    color: #0F0F28;
    text-decoration: underline;
}

.linkStyle:hover {
    color: #0F0F28;
    text-decoration: underline;
}



@media(max-width:1100px) {
    .spareElement {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media(max-width:900px) {
    .spareElement {
        display: none;
    }

    .feedbackWrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .sendFormWrapper {
        margin-top: 50px;
    }
}

@media(max-width:450px) {

    .sendFormWrapper {
        margin-top: 100px;
    }

    .HeaderText {
        font-size: 32px;
    }

    .Header2Text {
        font-size: 20px;
    }
}