.ProjectsWrapper {
    background: #FFF;
    max-width: 1920px;
    border-radius: 45px 45px 0px 0px;
    width: 100%;
    margin-top: -200px;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.firstText {
    color: var(--2, #0F0F28);
    /* НЦ Заголовок */
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.secondText {
    color: var(--text, #201644);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    max-width: 1090px;
}

.thirdText {
    color: var(--2, #0F0F28);
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

@media(max-width:1000px) {
    .ProjectsWrapper {
        border-radius: 15px 15px 0px 0px;
    }

}

@media(max-width:700px) {
    .firstText {
        font-size: 20px;
    }

    .secondText {
        font-size: 12px;
    }

    .thirdText {
        font-size: 32px;
    }
}

.CompanyDescriptionWrapper {
    height: 754px;
    background-image: url("../../../../../Images/VirImg.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 100px;
}

.HeaderText {
    max-width: 975px;
    color: var(--c, #FAFAFF);
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.SmallText {
    max-width: 745px;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.buyWrapper {
    width: 1920px;
    background: #4A6FF5;
    border-radius: 35px;
}

.buyWrapper2 {
    width: 1920px;
    border: 3px solid #4A6FF5;
    background: var(--c, #FAFAFF);
    border-radius: 35px;
}

.textLicense {
    color: var(--c, #FAFAFF);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.priceTextLicense {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.buyWrapperButton {
    cursor: pointer;
    margin-top: 31px;
    border-radius: 20px;
    background: var(--c, #FAFAFF);
    width: 514px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4A6FF5;
    font-family: "Manrope";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.lineWrapper {
    width: 1320px;
    padding: 10px;
    display: flex;
}

@media(max-width:1920px) {
    .buyWrapper {
        width: 100vw;
    }

    .buyWrapper2 {
        width: 100vw;
    }
}


@media(max-width:1550px) {
    .CompanyDescriptionWrapper {
        height: 550px;
        background-size: cover;
    }
}

@media(max-width:1100px) {
    .CompanyDescriptionWrapper {
        height: 400px;
        background-size: cover;
    }

    .lineWrapper {
        flex-direction: column;
    }
}

@media(max-width:900px) {
    .CompanyDescriptionWrapper {
        height: 300px;
        background-size: cover;
    }
}

@media(max-width:550px) {
    .buyWrapperButton {
        width: 330px;
    }
}


@media(max-width:750px) {
    .CompanyDescriptionWrapper {
        background-size: contain;
        background-image: url("../../../../../Images/VirMini.webp");
        width: 100%;
        height: 1000px;
    }

    .ProjectsWrapper {
        margin-top: -100px;
    }
}