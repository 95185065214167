.contactJustifier{
    display: flex;
    justify-content: center;
}
.contactDescWrapper {
    height: 810px;
    background-color: #dfdee2;
    background-image: url("../../../Images/contact_bg.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow:hidden;
    z-index: 1;
    width: 100%;
    max-width: 1920px;
}
.topTextBlock{
    width: 100%;
    margin-left: 300px;
    margin-top: 200px;
}
.topTextTitle{
    color: #0F0F28;
    font-family: Manrope;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; 
}

.topTextSubTitle{
    margin-top: 30px;
    color: #4A6FF5;
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

@media(max-width:1200px){
    .topTextBlock{
        width: auto;
        margin-left: 0px;
        margin-top: 10vw;
    }
    .contactDescWrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 68vw;
    }
}

@media(max-width:920px){
    .topTextBlock{
        margin-top: 100px;
    }
    .contactDescWrapper{
        height: 75vw;
    }
}

@media(max-width: 768px) {
    .topTextTitle{
        font-size: 48px;
        text-align: center;
    }
    .topTextSubTitle{
        font-size: 25px;
    }
    .contactDescWrapper{
        height: 65vw;
    }
}

@media(max-width: 650px) {
    .contactDescWrapper{
        height: 75vw;
    }
}

@media(max-width: 560px) {
    .topTextSubTitle{
        font-size: 20px;
        width: 100%;
    }
}

@media(max-width: 480px) {
    .topTextTitle{
        font-size: 40px;
        text-align: center;
    }
    .contactDescWrapper{
        height: 300px;
    }
}