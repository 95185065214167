.elementWrapper {
    margin-left: 50px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: 295px;
    height: 416px;
    object-fit: cover;
    background-color: #2b3155;
}

.aboutScrollBlock {
    width: 295px;
    height: 416px;
    margin-right: 37px;
    flex-shrink: 0;
    cursor: pointer;
}

.aboutScrollBlock:active,
.aboutScrollBlock:focus {
    outline: none;
}

.aboutScrollBlock::-moz-focus-inner {
    border: 0;
}

.aboutScrollBlockImage {
    width: 295px;
    height: 416px;
    object-fit: cover;
    flex-shrink: 1;

}

.forScaleAboutScrollBlockImage {
    user-select: none;
    width: 295px;
    height: 416px;
    object-fit: contain;
    flex-shrink: 1;
    transition: 0.3s;
    transition-property: all;

}

.bigImage {
    background: rgb(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
}