.CompanyDescriptionWrapper {
    height: 810px;
    width: 1920px;
    background-color: #455998;
    background-image: url("../../../../../Images/RoboLogo/robologoBackground.webp");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    overflow:hidden;

}

.HeaderText {
    max-width: 1320px;
    color: var(--c, #FAFAFF);
    /* НЦ Супер-Заголовок */
    font-family: "Manrope";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.SmallText {
    max-width: 745px;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.AdvantagesWrapper {
    display: flex;
}

.AdvantagesBox {
    display: flex;
}

@media(max-width:1200px) {
    .AdvantagesWrapper {
        flex-direction: column;
    }
    .CompanyDescriptionWrapper {
        height: 1200px;
        background-size: cover;
    }
}

@media(max-width:750px) {
    .AdvantagesBox {
        flex-direction: column;
    }

    .HeaderText {
        font-size: 32px;
    }

    .SmallText {
        font-size: 12px;
    }

    .CompanyDescriptionWrapper {
        height: 600px;
        background-size: cover;
    }
}



.achievementHolder {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 50px;
}


.achievementBlock {
    width: 230px;
    height: 123px;
    background: #4A6FF5;
    box-shadow: 2px 2px 8px rgba(89, 116, 130, 0.25);
    border-radius: 25px 5px 25px 25px;
    margin-right: 25.55px;
    margin-bottom: 30px;
    margin-left: 10px;
}

.achievementBlockText {
    color: var(--c, #FAFAFF);
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.achievementBlockHeader {
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
}

.bottomPart {
    border-radius: 45px 45px 0px 0px;
    background: #FFF;
    height: 400px;
    width: 100%;

}