.bgBox{
    height: 400px;
    width: 400px;
    border-radius: 45px;
    background-size: contain;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    cursor: pointer;
}
.bgBox:last-child{
    margin-left: 4.4%;
}
/* .bgBox:nth-last-child(2){
    margin-left: 4.4%;
} */

.bgTextHolder{
    width: 100%;
    max-height: 81.25%;
    border-radius: 45px;
    background: rgba(0, 17, 92, 0.75);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.bgTitle{
    color: #FAFAFF;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; 
    margin-top: 15%;
    margin-left: 9%;
    margin-right: 9%;
}

.bgSubText{
    color: #FAFAFF;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 5%;
    margin-bottom: 12%;
}

@media(max-width:1500px){
    .bgBox{
        height: 26.66vw;
        width: 26.66vw;
    }
    .bgTitle{
        font-size: 1.6vw;
    }
    .bgSubText{
        font-size: 1.067vw;
    }
}
@media(max-width:1200px){
    .bgBox{
        height: 26.66vw;
        width: 26.66vw;
    }
    .bgTitle{
        margin-top: 10%;
        font-size: 19px;
    }
    .bgSubText{
        font-size: 12px;
    }
}

@media(max-width:1000px){
    .bgBox{
        height: 41vw;
        width: 41vw;
    }
    .bgBox:last-child{
        margin-left: 0%;
    }
    .bgTitle{
        margin-top: 15%;
        font-size: 24px;
    }
    .bgSubText{
        font-size: 16px;
    }
}

@media(max-width:850px){
    .bgTitle{
        margin-top: 10%;
        font-size: 20px;
    }
    .bgSubText{
        font-size: 14px;
    }
}

@media(max-width:768px){
    .bgBox{
        height: 88vw;
        width: 88vw;
    }
    .bgTextHolder{
        max-height: 70%;
    }
    .bgTitle{
        margin-top: 16%;
        font-size: 24px;
    }
    .bgSubText{
        font-size: 17px;
    }
}

@media(max-width:480px){
    .bgTextHolder{
        max-height: 81.25%;
    }
    .bgTitle{
        margin-top: 20%;
        font-size: 24px;
    }
    .bgSubText{
        font-size: 16px;
    }
}

@media(max-width:420px){
    .bgTitle{
        margin-top: 15%;
        font-size: 20px;
    }
    .bgSubText{
        font-size: 14px;
    }
}