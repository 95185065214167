.StackWrapper {
    border-top: 1px solid var(---, #576982);
    border-bottom: 1px solid var(---, #576982);
    /* height: 380px; */
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerText {
    color: var(--2, #0F0F28);
    text-align: center;
    /* НЦ Заголовок */
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.textStyle {
    color: var(--2, #0F0F28);
    /* НЦ Выделение */
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 20px;
    text-align: center;
}

.imgWrapper{
    height: 130px;
    display: flex;
    align-items: center;
}

.wrapElement{
    margin: 30px;
}