.PartnersWrapper {
    height: 270px;
    border-radius: 40px;
    background: #FFF;
    margin-top: 100px;
    margin-bottom: 0px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.426);
    z-index: 3;
}

.imagesWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 150px;
    position: relative;
}

.elementWrapper {
    margin-left: 50px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.imagesWrapperPartners {
    width: 100%;
    overflow: auto;
}

@media(max-width:900px) {
    .imagesWrapper {
        margin-left: 10px;
    }

    .firstImageWrapper {
        width: 150px;
    }

    .elementWrapper {
        width: 100px;

    }
}